import React from "react";
import "./about.css";

export default function About() {
  return (
    <section id="about" className="about">
      <h1 className="sectionHead">About me</h1>
      <p>
        I think my first calling as a dev was when I was around 9 years old and I wrote a script hidden behind
        a youtube link that would restart my friends computers. My favorite part about development is chasing the
        ever fleeting skill-ceiling, theres room for infinite growth and that's truly exciting. I enjoy spending my time
        creating web applications and navigating new technologies.
        When I'm not pulling my hair out tackling leetcode algorithms or building applications I really enjoy Hiking, Kayaking,
        and playing piano.
      </p>
      <p>
        My primary tech stack at the moment, but not limited to, is Javascript, Node.js, React, Redux, PostgreSQL,
        HTML5, CSS3.
      </p>
      <p>
        Working On: Building an Ecommerce Application in Laravel modeled after amazon. <br/>
        Reading: Robert Sedgewick - Alogrithms.
      </p>
    </section>
  );
}
