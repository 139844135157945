import React, { Component } from "react";
import Home from "../home/Home";
import About from "../about/About";
import "./app.css";
import ProjectList from "../projects/ProjectList";

export default class App extends Component {
  render() {
    return (
      <>
        <Home />
        <About />
        <ProjectList />
      </>
    );
  }
}
