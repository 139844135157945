import React, { useState } from 'react';
import './nav.css';

export default function Nav() {

  const [flag, setFlag] = useState(true)
  

  return (
    <header>
      <div className={flag ? 'sidenav' : 'sidenavClose'}>
        <button className='closebtn' onClick={() => setFlag(!flag) }><i class="fas fa-times"></i></button>
        <a onClick={() => setFlag(!flag) }href="#about">About</a>
        <a onClick={() => setFlag(!flag) }href="#projects">Projects</a>
      </div>
      <div className='topInline'>
      <button className='topham' onClick={() => setFlag(!flag) }><i class="fas fa-bars"></i></button>
      <a target='blank' href='https://github.com/maleckim'><i class="fab fa-github"></i></a>
      <a href='https://www.linkedin.com/in/maleckimatthew/'><i class="fab fa-linkedin-in"></i></a>
      <a href='mailto:maleckimatthewm@gmail.com'><i class="far fa-envelope"></i></a>
      </div>
    </header>
  );
}
