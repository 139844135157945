import React from "react";


export default function projects(props) {
  return (
    <div className="project">
      <h1>{props.name}</h1>
      <h4>{props.timeline}</h4>
      <img
        src={props.image}
        className="projectIMG"
        alt={`${props.name} screenshot`}
      />
      <div className="buttons">
        {props.prevbutton}
        {props.nextbutton}
      </div>
      <p>{props.description}</p>
      <p>{props.technologies}</p>
      <a target='blank' href={props.link}>Github: {props.name}</a>
      <a target='blank' href={props.live}>Live: {props.name}</a>
    </div>
  );
}
