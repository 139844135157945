import React, {useState} from "react";
import Project from "./Project";
import notecardimage from "../assets/notecards.png";
import feedback from "../assets/feedback.PNG";
import landingpage from "../assets/landingpage.png";
import notes from "../assets/notes.png";
import results from "../assets/results.PNG";
import quizFirst from "../assets/QuizzAppFirst.PNG";
import quizMain from "../assets/QuizzAppMain.PNG";
import quizFeedback from "../assets/QuizzAppFeedback.PNG";
import quizSummary from "../assets/QuizzAppSummary.PNG";
import magnetLanding from "../assets/magnetLanding.PNG";
import magnetResults from "../assets/magnetResults.PNG";
import magnetSecond from "../assets/magnetSecond.PNG";
import npmmLand from "../assets/npmmLand.PNG";
import npmmSearch from "../assets/npmmSearch.PNG";
import npmmSearchResults from "../assets/npmmSearchResult.PNG";
import npmmCollection from "../assets/npmmCollection.PNG";
import npmmCLI from "../assets/cli.gif";

import "./Project.css";

export default function ProjectList() {
  const [count, setCount] = useState(0);
  
  const images = {
    quiz: [quizMain, quizFirst, quizFeedback, quizSummary],
    magnet: [magnetLanding, magnetSecond, magnetResults],
    nerd: [landingpage, notecardimage, notes, feedback],
    npmm: [npmmLand, npmmSearch, npmmSearchResults, npmmCollection]
  }

  return (
    <section id="projects" className="projectList">
      <h1>projects</h1>
      <Project
        name="npmm"
        // timeline="May 2020. So now what? It's been around 6 months since the infamous snake quiz and I think it's time to create something viable.
        // This project was a bit different because it was my first experience working in a group of more than three people. In matter of 3 weeks we
        // managed to create something pretty impressive (relatively). This project wasn't only in grander scope than those previous, but it was
        // a learning curve since I didn't have full control over everything happening. We had 5 people working together, and we couldn't put all
        // of our sweaty hands on the client at once, so naturally I was banished to architecting the API and database. That's fine by me.
        // It made me realize how important communication is. It was essentially a 3 legged race with 5 people. I never imagined it would have gone as
        // smoothly as it did, opening my eyes to how powerful several great minds working together really is."
        description="NPMM's purpose was to browse and save npm packages from the web into personalized collections, essentially boilerplating 
        npm packages. Then supplying the user the ability to seemlessly import export those 'collections' from the command line"
        technologies="Front-end: React, Redux, JWT, HTML5, CSS3, Javascript, Jest. Back-end: Node, Knex, Express, Mocha and Chai"
        link='https://github.com/dannydi12/npmm-client'
        live='https://npmm.dev/'
        image={images.npmm[count]}
        nextbutton = {<button onClick={() => setCount(count < images.npmm.length - 1 ? count + 1 : 0)} ><i class="fas fa-arrow-right"></i></button>}
        prevbutton = {<button onClick={() => setCount(count > 0 ? count - 1 : 0)} ><i class="fas fa-arrow-left"></i></button>}
      />
      <Project 
        name="npmm cli tool"
        description="Command line tool for integration from npmm web application to development"
        technologies="Commander, Node"
        link="https://github.com/maleckim/npmm-cli"
        image={npmmCLI}
      />
      <Project
        name="nerd-pocket"
        // timeline="Apr 2020. Here we are a few months later. I've been spending a lot of my time learning about data structures creating my own 
        // RESTful API's. So let's make a full stack application! As you can see I still style the things I create like a toddler with a dull crayon
        // but in regards to my snake quiz this product feels almost infinitely better. The code is still a little sloppy but some major takeaways and
        // learning points where, built a full stack application from scratch, learned how to deploy a server and client and most importantly to get
        // hired onto a team with a quality UI/UX designer."
        description="My first full-stack application built from scratch. The purpose of this app is too encapsulate some classic studying techniques into one easy to manage place. 
        This site replicates notecard usage, note-taking, and related reminders"
        technologies="Front-end: React, HTML5, CSS3, Javascript, Jest. Back-end: Node, Knex, Express, Mocha and Chai"
        link='https://github.com/maleckim/nerd-pocket-client'
        live='https://nerd-pocket.now.sh/'
        image={images.nerd[count]}
        nextbutton = {<button onClick={() => setCount(count < images.nerd.length - 1 ? count + 1 : 0)} ><i class="fas fa-arrow-right"></i></button>}
        prevbutton = {<button onClick={() => setCount(count > 0 ? count - 1 : 0)} ><i class="fas fa-arrow-left"></i></button>}
      />
      <Project
        name="Magnet"
        // timeline="Jan 2020. It's been a couple months at this point, I've gained basic understanding of API's and have alot better grasp on Javascript
        // as a whole. This was my first time dealing with API's and I decided to pick one that returned an infinite loop of nested objects and arrays. 
        // It was a great learning experience for beginning to navigate nested structures and functional programming. Compared to
        // the snake quizz application this is a sizeable jump, still very crude and scrapped upon completion, it was another step in the right
        // direction. WARNING, before you view the screenshots, if you have epilepsy you better skip this portion."
        description="Another early project. Implementing basic understanding of API's. The project can be boiled down to a vacation companion, 
        enter two airports get some relative information"
        technologies="Front-end: HTML5, CSS3, Javascript, JQuery."
        link='https://github.com/maleckim/Magnet'
        live='https://maleckim.github.io/Magnet/'
        image={images.magnet[count]}
        nextbutton = {<button onClick={() => setCount(count < images.magnet.length - 1 ? count + 1 : 0)} ><i class="fas fa-arrow-right"></i></button>}
        prevbutton = {<button onClick={() => setCount(count > 0 ? count - 1 : 0)} ><i class="fas fa-arrow-left"></i></button>}
      />
      <Project
        name="Snake Quizzz"
        // timeline="November 2019. This project was essentially my 'Hello World!' project, I was extremely yellow when it came to Web Development
        // and I may have even considered JQuery 'Abstract' concept. I remember all the time and effort I put into this now to only look back at it
        // and feel slightly embarassed. It has a special place in my heart though, and if nothing it serves as a great reminder how far I've come"
        description="My 'Hello World' project. The purpose of my snake quiz was, you guessed it! To quiz you about snakes. Go ahead and skip viewing the github
        theres alot of REALLY advanced stuff going on in there"
        technologies="Front-end: HTML5, CSS3, Javascript, JQuery. Back-end: whats that?"
        link='https://github.com/maleckim/QuizzApp'
        live='https://maleckim.github.io/QuizzApp/'
        image={images.quiz[count]}
        nextbutton = {<button onClick={() => setCount(count < images.quiz.length - 1 ? count + 1 : 0)} ><i class="fas fa-arrow-right"></i></button>}
        prevbutton = {<button onClick={() => setCount(count > 0 ? count - 1 : 0)} ><i class="fas fa-arrow-left"></i></button>}
      />
    </section>
  );
}