import React from "react";
import Nav from "../topnav/nav";
import "./home.css";

export default function Home() {
  return (
    <>
      <Nav />
      <div className="home">
        <h1>Matthew Malecki</h1>
        <h1 className="bottomHead">Just trying to make things work.</h1>
        <p>
          I'm a software engineer based in Chicago, IL with emphasis in
          front-end/back-end development and occasional design.
        </p>
      </div>
    </>
  );
}
